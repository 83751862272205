// @flow

import React from 'react'
import classNames from 'classnames'
import { useIntl } from 'gatsby-plugin-intl'
import { applyStylingHoc, type StyleOuterProps, type StyleProps } from '@fcse/tsbob/dist/lib/styling'
import IntroTeaser from '../../../components/IntroTeaser'
import Vacancy from './../Vacancy'

import messages from './Vacancies.lang'
import baseStyles from './Vacancies.scss'
import { StaticImage } from 'gatsby-plugin-image'

const image = (
  <StaticImage src="../../../images/pictures/vacancies_image.png" objectFit="cover" alt="" className="image" />
)

const imageManager = (
  <StaticImage src="../../../images/vacancies/image_manager.jpg" objectFit="cover" alt="" className="imageCover" />
)

const imageArchitect = (
  <StaticImage src="../../../images/vacancies/image_architect.jpg" objectFit="cover" alt="" className="imageCover" />
)

const imageDesigner = (
  <StaticImage src="../../../images/vacancies/image_designer.jpg" objectFit="cover" alt="" className="imageCover" />
)

const imageTechManager = (
  <StaticImage src="../../../images/vacancies/image_tech_manager.jpg" objectFit="cover" alt="" className="imageCover" />
)

type Props = {
  ...StyleProps
}

const Vacancies = ({ styles }: Props) => {
  const { formatMessage, locale } = useIntl()

  return (
    <IntroTeaser
      styles={styles.root}
      title={formatMessage(messages.title)}
      copy={
        <>
          <p className={styles.info}>{formatMessage(messages.info)}</p>
          {locale === 'en' && (
            <Vacancy
              img={imageArchitect}
              imgIcon="icon-complex"
              title={formatMessage(messages.seniorPHPFullStackTitle)}
              info={formatMessage(messages.seniorPHPFullStackInfo)}
              btnLink="/career/vacancies/senior-full-stack-developer-php/"
            />
          )}
          <Vacancy
            img={imageDesigner}
            imgIcon="icon-development2"
            title={formatMessage(messages.fullstackDevTitle)}
            info={formatMessage(messages.fullstackDevInfo)}
            btnLink="/career/vacancies/fullstack-developer/"
          />
          {locale === 'de' && (
            <Vacancy
              img={imageDesigner}
              imgIcon="icon-development2"
              title={formatMessage(messages.seniorUiUxDesignerTitle)}
              info={formatMessage(messages.seniorUiUxDesignerInfo)}
              btnLink='/career/vacancies/senior-ui-ux-designer/'
            />
          ) }
          {locale === 'de' && (
            <Vacancy
              img={imageDesigner}
              imgIcon="icon-platform"
              title={formatMessage(messages.BusinessDeveloperSalesManagerTitle)}
              info={formatMessage(messages.BusinessDeveloperSalesManagerInfo)}
              btnLink="/career/vacancies/business-developer-sales-manager/"
            />
          )}
          {locale === 'de' && (
            <Vacancy
              img={imageManager}
              imgIcon="icon-management"
              title={formatMessage(messages.managerTitle)}
              info={formatMessage(messages.managerInfo)}
              btnLink="/career/vacancies/project-manager/"
            />
          )}
          <Vacancy
            img={imageManager}
            imgIcon="icon-complex"
            title={formatMessage(messages.technicalManagerTitle)}
            info={formatMessage(messages.technicalManagerInfo)}
            btnLink="/career/vacancies/technical-project-manager/"
          />
          <Vacancy
            img={imageArchitect}
            imgIcon="icon-pen-and-monitor"
            title={formatMessage(messages.architectTitle)}
            info={formatMessage(messages.architectInfo)}
            btnLink="/career/vacancies/senior-software-architect/"
          />
          <a
            href={'/' + locale + '/privacy-policy-applicants/'}
            target="_blank"
            rel="noopener noreferrer"
            className={styles.link}
          >
            <span className={classNames(styles.linkIcon, 'icon-right')} />
            {formatMessage(messages.dataProtectionPdfLink)}
          </a>
        </>
      }
      image={image}
      fullWidth
      reversed
    />
  )
}

export default applyStylingHoc(baseStyles)<$Diff<Props, StyleProps> & StyleOuterProps, Function>(Vacancies)
